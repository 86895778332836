(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Postponements
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('Postponements', Postponements);

  function Postponements($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/matches/:id/postponements', {id: '@_id'});
  }
}());
